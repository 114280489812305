// 
// 
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.


// Color Scheme
// Uncomment the variables below and change the hex values.

$primary:      #6e0812;
$primary-2:     #ab2937;
$primary-3:     #e9d1ca;
$secondary:     #ade5ed;
$secondary-2:   #0c8899;
$bg-primary-2-alt:     #0c8899;
$primary-alt:   #c3606b;

$color-white: #ffffff;
$color-black: #000000;
$primary-dark: #8f6474;

// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
    "secondary":  $secondary,
    "bg-primary-2-alt": $bg-primary-2-alt,
    "list-group-active-color": $primary,
    "dropdown-link-active-color": $primary,
    "pagination-active-color": $color-white,
    "pagination-active-border-color": $primary,
    "pagination-active-bg": $primary,
    "pagination-color": $primary,
    "pagination-hover-color": $color-black
);

$theme-colors: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
    "secondary":  $secondary,
    "bg-primary-2-alt": $bg-primary-2-alt,
    "list-group-active-color": $primary,
    "dropdown-link-active-color": $primary,
    "pagination-active-color": $color-white,
    "pagination-active-border-color": $primary,
    "pagination-active-bg": $primary,
    "pagination-color": $primary,
    "pagination-hover-color": $color-black
);

$link-hover-color:  $primary-dark;
$list-group-active-color:   $primary;
$list-group-active-bg:  $primary;
$list-group-active-border-color: $primary;
$dropdown-link-active-color:    $primary;
$pagination-active-color:   $color-white;
$pagination-active-bg: $primary;
$pagination-active-border-color: $primary;
$pagination-color: $primary;
$pagination-hover-color: $color-black;