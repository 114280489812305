// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.
.avatar { max-width: initial !important; }
.avatar-square { border-radius: initial !important; }
.cursor-pointer { cursor: pointer; }
.cursor-pointer:hover { opacity: 0.8; }
.bg-primary-2-alt { background-color: $bg-primary-2-alt; }
.btn-outline-secondary-2 { border: 1px $secondary-2 solid; color: white !important; }
.btn-outline-secondary-2:hover { background-color: $secondary-2; color: white !important; }

.btn-secondary-2 { background-color: $secondary-2; color: white; }
.btn-secondary-2:hover { background-color: #037181; color: white; }

.bg-secondary-2 { background-color: $secondary-2; }

.text-black { color: #000; }
.text-secondary-2 { color: $secondary-2; }
.text-primary { color: $primary-alt !important; }

.navbar[data-sticky="top"].scrolled.navbar-dark {
	background-color: $primary;
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: $secondary-2;
    background-color: $secondary-2;
}

.breadcrumb { background-color: #ffffff40 !important; }

.navbar[data-sticky="top"].scrolled.navbar-dark { background-color: $secondary-2; } 
section.bg-secondary .breadcrumb .breadcrumb-item { color: $secondary-2; }
section.bg-secondary .breadcrumb .breadcrumb-item a { color: #000; }
section.bg-secondary .breadcrumb .breadcrumb-item a:hover { color: $secondary-2; }

.dropdown-item.active, .dropdown-item:active { color: $secondary; }

.flBtn {
  position: fixed;
  z-index: 1000;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  text-decoration: none;
}
.flBtn ul {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  text-decoration: none;  
  position: absolute;
  visibility: hidden;
}

.flBtn input {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.flBtn input:checked ~ ul {
  visibility: visible;
}
.flBtn input:checked ~ ul li {
  visibility: visible;
  opacity: 1;
}

.flBtn input:hover + a {
  color: #383838;
  background: $secondary;
}
.flBtn input:hover + a[tooltip]:before {
  visibility: visible;
  opacity: 1;
}
.flBtn a {
  border-radius: 6px;
  position: relative;
  z-index: 2;
  display: block;
  vertical-align: middle;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  background: $secondary-2;
  color: #ffffff;
  transition: background 0.5s;
  -o-transition: background 0.5s;
  -ms-transition: background 0.5s;
  -moz-transition: background 0.5s;
  -webkit-transition: background 0.5s;
  border: 1px #fff solid;
}
.flBtn a:hover {
  color: #383838;
  background: $secondary;
}
.flBtn ul li {
  display: inline-block;
  margin: 5px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
.flBtn [tooltip]:before {
  position: absolute;
  content: attr(tooltip);
  visibility: hidden;
  opacity: 0;
  padding: 0 10px;
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  transition: all 0.5s;
}
.flBtn [tooltip]:hover:before {
  visibility: visible;
  opacity: 1;
}

.flBtn-size-medium a {
  width: 160px;
  height: 46px;
  line-height: 46px;
  font-size: 14px;
}
.flBtn-size-medium ul a {
  width: 46px;
  height: 46px;
  line-height: 42px;
  font-size: 0px;
}
.flBtn-size-medium [tooltip]:before {
  height: 46px;
  line-height: 46px;
  font-size: 0px;
}

.flBtn-position-br {
  bottom: 20px;
  right: 20px;
}
.flBtn-position-br [tooltip][data-role]:before {
  right: 100%;
  bottom: 100%;
}
.flBtn-position-br .flBtn-first {
  right: 50%;
  transform: translateX(50%);
  bottom: 100%;
}
.flBtn-position-br .flBtn-first [tooltip]:before {
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 5px;
}
.flBtn-position-br .flBtn-second {
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  right: 100%;
}
.flBtn-position-br .flBtn-second [tooltip]:before {
  right: 100%;
  bottom: 100%;
  margin-bottom: 5px;
}

@media all and (max-width: 767px){
    .flBtn a {
      border-radius: 50%;
    }
    .flBtn-size-medium a {
      width: 46px;
      height: 46px;
      line-height: 46px;
      font-size: 0px;
    }
}
